import SmartLook from "smartlook-client";
import {useBaseStore} from "~/stores/base";
import {SUB_STATUS} from "~/utils/constants";
import {parseUserAgent} from "~/utils/common";
import Cookies from "js-cookie";
import {getLocation} from "~/api/api.app";

let isGlobalPropertiesSet = false
let setCallbacks = [];

const PAGE_LABEL = {
  REGISTER: "Register",
  SUBSCRIBE: "Subscribe",
  HOME: "Home",
  MENU: "Menu",
  PRODUCT_DETAIL: "Product_Detail",
  SEARCH_RESULT: "Search_Result",
  COLLECTION: "Collection",
  CATEGORY: "Category",
  ALL_STYLE: "All_Style",
  NEW_ARRIVALS: "New_Arrivals",
  ALL_BRANDS: "All_Brands",
  BRAND: "Brand",
  AI: "AI",
  CART: "My_BNTO",
}

const getGlobalProps = async () => {
  const userInfo = computed(() => useBaseStore().getterUserInfo)
  const isLogin = computed(() => useBaseStore().getterIsLogin)
  const isMobile = computed(() => useBaseStore().getterIsMobile)
  const apiVersion = computed(() => useBaseStore().getterApiVersion)
  const appInit = computed(() => useBaseStore().getterAppInit)

  const user_id = userInfo.value.userId

  let user_type
  if (isLogin.value) {
    user_type = "Registered"
    if (userInfo.value.isMember && (userInfo.value.subStatus === SUB_STATUS.SUBSCRIBED || userInfo.value.subStatus === SUB_STATUS.PAUSED)) {
      user_type = "Subscribed"
    }
  } else {
    user_type = "Guest"
  }

  const device_type = isMobile.value ? "Mobile" : "Desktop"

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const screen_resolution = `${screenWidth}x${screenHeight}`

  const {city, country, ip, loc, org, postal, region, timezone} = await getLocation()

  return {
    user_id,
    user_type, // Guest、 Registered、 Subscribed
    session_id: appInit.value.sessionId, // From API Init.
    // trace_id: "", // From every single API.
    platform: "Browser", // App：iOS Web：Browser
    app_version: useRuntimeConfig().public.version,
    api_version: apiVersion.value, // From every single API's Response Header.
    device_type, // App：iOS Web：Desktop / Mobile
    os_name: parseUserAgent(navigator.userAgent).os, // Windows / macOS / iOS
    os_version: parseUserAgent(navigator.userAgent).osVersion,
    device_model: parseUserAgent(navigator.userAgent).device,
    browser_name: parseUserAgent(navigator.userAgent).browser,
    browser_version: parseUserAgent(navigator.userAgent).browserVersion,
    language: navigator.language || navigator.userLanguage,
    timezone,
    // app_source: "",
    screen_resolution,
    country,
    region,
    city,
    ip,
    network_type: Cookies.get("network"),
    utm_source: useRoute().query.utm_source || "",
    utm_medium: useRoute().query.utm_medium || "",
    utm_campaign: useRoute().query.utm_campaign || "",
    utm_term: useRoute().query.utm_term || "",
    utm_content: useRoute().query.utm_content || "",
  }
}

const isFirstDay = (firstSessionDate) => {
  const msDiff = new Date() - firstSessionDate
  const msIn24Hours = 24 * 60 * 60 * 1000; // 24小时的毫秒数
  return msDiff <= msIn24Hours
}

const publicProps = () => {
  const pageLabel = computed(() => useBaseStore().getterPageLabel)
  const sessionTimeStamp = computed(() => useBaseStore().getterSessionTimeStamp)
  let isFirstSession = false
  let firstSessionDate = localStorage.getItem("FIRST_SESSION_DATE")
  if (!firstSessionDate) {
    isFirstSession = true
    firstSessionDate = new Date()
    localStorage.setItem("FIRST_SESSION_DATE", firstSessionDate)
  } else {
    firstSessionDate = new Date(firstSessionDate)
  }
  return {
    page_url: window.location.href,
    page_url_params: useRoute().fullPath,
    page_label: pageLabel.value,
    session_duration: Math.round((new Date().getTime() - sessionTimeStamp.value) / 1000), // 四舍五入取整 // session_id创建至当前的时间跨度，单位为秒
    is_first_session: isFirstSession, // 是否为用户的首次访问（设备维度）
    is_first_day: isFirstDay(firstSessionDate), // 是否为用户的首次访问或24h内的再次访问（设备维度）
  }
}

const elementProps = {
  element_type: "",
  element_title: "",
  element_label: "",
  element_params: "",
  element_index: "",
}

async function smartLookProperties() {
  isGlobalPropertiesSet = false
  const properties = await getGlobalProps()
  SmartLook.properties(properties)
  isGlobalPropertiesSet = true;
  console.log("SmartLook Global Properties set")
  console.table(properties)

  // 执行所有等待的回调
  setCallbacks.forEach(callback => callback());
  setCallbacks = [];  // 清空回调队列
}

// 封装的等待执行函数
function executeWhenReady(callback) {
  if (isGlobalPropertiesSet) {
    // 如果 ready 已经完成，立即执行回调
    callback();
  } else {
    // 否则将回调添加到等待队列中
    setCallbacks.push(callback);
  }
}

function smartLookIdentify() {
  const isLogin = useBaseStore().getterIsLogin
  if (isLogin) {
    const userInfo = useBaseStore().getterUserInfo

    SmartLook.identify(userInfo.userId, {
      UserID: userInfo.userId,
      Email: userInfo.email,
      DeviceID: useBaseStore().getterDeviceId
    });
    console.log('SmartLook is identified')
  }
}

function smartLookTrackPageView() {
  executeWhenReady(() => {
    const props = publicProps()
    console.table({EVENT: "Page_View", ...props})
    SmartLook.track('Page_View', props);
  })
}

const ELEMENT_TYPE = {
  NAV: 'Nav',
  BUTTON: "Button",
  INPUT: "Input",
  IMAGE: "Image",
  PRODUCT_CARD: "Product_Card",
  LINK: "Link",
}

const ELEMENT_TITLE = {
  HOME: "Home",
  MENU: "Menu",
  WISHLIST: "Wishlist",
  BNTO: "Bnto",
  AI_ENTRANCE: "AI Entrance",
  SEARCH: "Search",
  ACCOUNT_ENTRANCE: "Account Entrance",
  BANNER: "Banner",
  COLLECTION_RECOMMEND: "Collection Recommend",
  BRAND_RECOMMEND: "Brand Recommend",
  VIEW_ALL_BRANDS: "View All Brands",
  BRAND_WALL: "Brand Wall",
  JOIN_NOW: "Join Now",
  MORE_INFO: "More Info",
  LOGIN: "Login",
  RESET_PASSWORD: "Reset_Password",
  TERMS_AND_CONDITIONS: "Terms & Conditions",
  PRIVACY_AND_POLICY: "Privacy & Policy",
  BACK: "Back",
  NEXT: "Next",
  RESEND: "Resend",
  CHECK: "Check",
  UNCHECK: "Uncheck",
  SHIPPING_ADDRESS_EDIT: "Shipping Address Edit",
  BILLING_INFO_EDIT: "Billing Info Edit",
  PROMO_CODE_APPLY: "Promo Code Apply",
  INVITATION_CODE_APPLY: "Invitation Code Apply",
}

const ELEMENT_LABEL = {
  HOME: "Home",
  MENU: "Menu",
  WISHLIST: "Wishlist",
  BNTO: "Bnto",
  AI_ENTRANCE: "AI_Entrance",
  SEARCH: "Search",
  ACCOUNT_ENTRANCE: "Account_Entrance",
  BANNER: "Banner",
  COLLECTION_RECOMMEND: "Collection_Recommend",
  BRAND_RECOMMEND: "Brand_Recommend",
  VIEW_ALL_BRANDS: "View_All_Brands",
  BRAND_WALL: "Brand_Wall",
  BNTO_INTRODUCE: "BNTO_Introduce",
  PLAN_AND_PRICING: "Plan_&_Pricing",
  LIKE: "Like",
  UNLIKE: "Unlike",
  ACCOUNT: "Account",
  LOGIN: "Login",
  REGISTER_LOGIN_DETAILS: "Register_Login_Details",
  REGISTER_VERIFICATION: "Register_Verification",
  SUBSCRIBE_OUR_PRICING: "Subscribe_Our_Pricing",
  SUBSCRIBE_SHIPPING_ADDRESS: "Subscribe_Shipping_Address",
  SUBSCRIBE_PAYMENT_INFO: "Subscribe_Payment_Info",
  SUBSCRIBE_REVIEW_AND_CONFIRM: "Subscribe_Review_&_Confirm",
  RESUBSCRIBE_REVIEW_AND_CONFIRM: "Resubscribe_Review_&_Confirm",
}

function formatElementLabel(str) {
  return str
    .split(' ') // 按空格分割字符串
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // 每个单词首字母大写
    .join('_'); // 用下划线连接单词
}

function formatElementTitle(str) {
  return str
    .split(' ') // 按空格分割字符串
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // 每个单词首字母大写
    .join(' '); // 用空格连接单词
}

/** =========== 曝光监控 =========== **/

let elementsToExposeObserve, exposeObserver

const smartLookElementExposeObserve = () => {
  // 防重
  if (elementsToExposeObserve && exposeObserver) {
    // 断开监听
    elementsToExposeObserve.forEach(element => {
      exposeObserver.disconnect();
    });
  }

  elementsToExposeObserve = document.querySelectorAll(`[element-expose-observe]`);

  // 创建 IntersectionObserver 实例
  exposeObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      // 当元素进入视口时
      if (entry.isIntersecting) {
        smartLookTrackExpose(entry.target)
      } else {
        // 当元素离开视口时
        // console.log(`元素 ${entry.target} 离开视口`);
      }
    });
  }, {
    threshold: 0.5  // 元素至少有 50% 显示在视口中时触发
  });

  // 启动监听
  elementsToExposeObserve.forEach(element => {
    exposeObserver.observe(element);
  });
}

function smartLookTrackExpose(element) {
  // todo 暂停1.2.2
  return
  executeWhenReady(() => {
    elementProps.element_type = element.attributes["element-type"]?.value || "";
    elementProps.element_title = element.attributes["element-title"]?.value || "";
    elementProps.element_label = element.attributes["element-label"]?.value || "";
    elementProps.element_params = element.attributes["element-params"]?.value || "";
    elementProps.element_index = element.attributes["element-index"]?.value || "";
    const props = Object.assign(elementProps, publicProps())
    console.table({EVENT: "Element_Impression", ...props})
    SmartLook.track('Element_Impression', props);
  })
}



/** =========== 点击监控 =========== **/

let elementsToClickObserve, clickObserver

const smartLookElementClickObserve = () =>{
  // 防重
  if (elementsToClickObserve && clickObserver) {
    // 断开监听
    elementsToClickObserve.forEach(element => {
      clickObserver.disconnect();
    });
  }

  elementsToClickObserve = document.querySelectorAll(`[element-click-observe]`);

  // 创建 IntersectionObserver 实例
  clickObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      // 当元素进入视口时
      if (entry.isIntersecting) {
        // 给元素添加点击事件监听器
        entry.target.addEventListener('click', smartLookTrackClick);
      } else {
        // 移除点击事件监听器（如果需要）
        // entry.target.removeEventListener('click', smartLookTrackClick);
      }
    });
  }, {
    threshold: 0.5  // 元素至少有 50% 显示在视口中时触发
  });

  // 启动监听
  elementsToClickObserve.forEach(element => {
    clickObserver.observe(element);
  });
}

function smartLookTrackClick(event) {
  // todo 暂停1.2.2
  return
  executeWhenReady(() => {
    const element = event.target;  // 获取被点击的元素
    if (!element) return
    const attributes = element.attributes || element.$attrs // $attrs 兼容 elementUI
    elementProps.element_type = attributes["element-type"]?.value || attributes["element-type"] || "";
    elementProps.element_title = attributes["element-title"]?.value || attributes["element-title"] || "";
    elementProps.element_label = attributes["element-label"]?.value || attributes["element-label"] || "";
    elementProps.element_params = attributes["element-params"]?.value || attributes["element-params"] || "";
    elementProps.element_index = attributes["element-index"]?.value || attributes["element-index"] || "";
    const props = Object.assign(elementProps, publicProps())
    console.table({EVENT: "Element_Click", ...props})
    SmartLook.track('Element_Click', props);
  })
}


/** =========== 异步加载监控 =========== **/
let elementsToAsyncObserve, asyncObserver

const smartLookElementAsyncObserve = () => {
  // 防重
  if (elementsToAsyncObserve && asyncObserver) {
    // 断开监听
    elementsToAsyncObserve.forEach(element => {
      asyncObserver.disconnect();
    });
  }

  elementsToAsyncObserve = document.querySelectorAll(`[element-async-observe]`);

  // 创建 IntersectionObserver 实例
  asyncObserver = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      // 判断元素是否包含属性
      const hasAttributeExpose = entry.target.hasAttribute('element-expose-observe');
      const hasAttributeClick = entry.target.hasAttribute('element-click-observe');

      // 当元素进入视口时
      if (entry.isIntersecting) {
        if (hasAttributeExpose) smartLookTrackExpose(entry.target)
        // 给元素添加点击事件监听器
        if (hasAttributeClick) entry.target.addEventListener('click', smartLookTrackClick);
      } else {
        // 当元素离开视口时
        // console.log(`元素 ${entry.target} 离开视口`);

        // 移除点击事件监听器（如果需要）
        // entry.target.removeEventListener('click', smartLookTrackClick);
      }
    });
  }, {
    threshold: 0.5  // 元素至少有 50% 显示在视口中时触发
  });

  // 启动监听
  elementsToAsyncObserve.forEach(element => {
    asyncObserver.observe(element);
  });
}

export {
  PAGE_LABEL,
  ELEMENT_TYPE,
  ELEMENT_TITLE,
  ELEMENT_LABEL,
  formatElementTitle,
  formatElementLabel,
  smartLookElementExposeObserve,
  smartLookElementClickObserve,
  smartLookElementAsyncObserve,
  smartLookTrackClick,
  smartLookProperties,
  smartLookIdentify,
  smartLookTrackPageView,
}
