import { defineNuxtPlugin } from '#app'
import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
  // GA4
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-WD3K4B3XPE';
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag(...args) {
    window.dataLayer.push(args);
  }
  gtag('js', new Date());
  gtag('config', 'G-WD3K4B3XPE');

  // GTM
  nuxtApp.vueApp.use(
    createGtm({
      id: 'GTM-MS45NNNM', // 替换为你的 GTM 容器 ID
      enabled: true, // 启用 GTM，默认值为 true
      debug: false, // 在开发模式中开启调试
      loadScript: true, // 自动加载 GTM 脚本
      vueRouter: nuxtApp.$router, // 绑定 Nuxt 路由
    }),
  )
})
