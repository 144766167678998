import { useGtm } from '@gtm-support/vue-gtm';

export function googleTrackPageView(screenName, currentPath) {
  useGtm().trackView(screenName, currentPath)
}

export function googleTrackCreateAccount() {
  // GTM
  useGtm().trackEvent({
    category: 'Create Account',
    action: 'Create Account',
  });
  // GA4
  window.dataLayer.push({
    event: 'create_account',
    account_status: 'success'
  });
}

export function googleTrackSubscribe() {
  // GTM
  useGtm().trackEvent({
    category: 'Subscription',
    action: 'Subscription',
  });
  // GA4
  window.dataLayer.push({
    event: 'subscription',
    subscription_type: 'new'
  });
}
